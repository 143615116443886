<template>
	<div>
		<v-col>
			<pui-select
				id="kpi-dashboard"
				:label="$t('kpi.dashboard')"
				itemText="name"
				itemValue="id"
				toplabel
				:items="dashboards"
				v-model="dashboard"
				clearable
				reactive
				:key="dashkey"
			>
			</pui-select>
			<pui-dashboard v-if="dashboard" :defaultDashboard="dashboard" />
		</v-col>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dashboard: null,
			dashboards: [],
			dashkey: 0,
			configs: []
		};
	},
	methods: {
		getDashboards(response) {
			let dasids = [];
			response.forEach((element) => {
				dasids.push(element.dashboardid);
			});

			if (dasids.length > 0) {
				const opts = {
					model: 'puidashboard',
					columns: ['id', 'name'],
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'in',
								data: dasids
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data.data) {
						this.dashboards = response.data.data;
						this.dashkey++;
					}
				});
			} else {
				this.dashboards = [];
			}
		}
	},
	computed: {},
	created() {
		const opts = {
			model: 'configportcompany',
			filter: {
				groupOp: 'and',
				groups: [],
				rules: [
					{
						field: 'companyid',
						op: 'eq',
						data: this.$store.state.session.properties.COMPANY_ID
					},
					{
						field: 'portauthorityid',
						op: 'eq',
						data: window.localStorage.getItem('workingPortAuthorityId')
					}
				]
			}
		};
		this.$puiRequests.postRequest('/puisearch', opts, (response) => {
			if (response.data.data) {
				this.configs = response.data.data;
				this.getDashboards(response.data.data);
			} else {
				this.dashboards = [];
			}
		});
	},
	mounted() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-kpi-dashboard', (newVal) => {
			var lupaModel = newVal.model;

			if (lupaModel !== null) {
				let iddashboard = lupaModel.id;
				const configSelected = this.configs.find((ele) => ele.dashboardid === iddashboard);
				window.localStorage.setItem('workingDashboardPort', configSelected.portid);

				this.$puiRequests.getRequest('/puidashboard/get', { id: iddashboard }, (response) => {
					if (response && response.data) {
						this.dashboard = {
							id: response.data.id,
							name: response.data.name,
							definition: JSON.parse(response.data.definition)
						};
					}
				});
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-kpi-dashboard');
	},
	watch: {}
};
</script>
<style scoped></style>
